import { FC } from 'react';
// components
import PageLayout from 'src/components/PageLayout';
import Intro from 'src/components/Intro';
// hooks
import useFaqIntroData from 'src/cms/data/faq/useFaqIntroData';
import useFaqMetaData from 'src/cms/data/faq/useFaqMetaData';
// sections
import Questions from './sections/Questions';
import { PageProps } from 'gatsby';

const Faq: FC<PageProps> = ({ location }) => {
  // hooks
  const { title, mobileTitle, description } = useFaqIntroData();
  const faqMetaData = useFaqMetaData();

  return (
    <PageLayout metaData={{ ...faqMetaData, href: location.href }}>
      <Intro
        title={title}
        mobileTitle={mobileTitle}
        description={description}
        icon='faqDrawing'
      />
      <Questions />
    </PageLayout>
  );
};

export default Faq;
