import { useCallback, useState } from 'react';

const useForceUpdate = () => {
  const [, updateObject] = useState<object>({});

  const forceUpdate = useCallback(() => {
    updateObject({});
  }, []);

  return { forceUpdate };
};

export default useForceUpdate;
