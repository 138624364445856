import { FC, useEffect } from 'react';
import classNames from 'classnames';
// components
import Section from 'src/components/Section';
import Typography from 'src/components/Typography';
// hooks
import useQuestionsData from 'src/cms/data/faq/useQuestionsData';
// animation
import { appearanceOfText } from 'src/animations/text';
import { onHTMLElementEnterOnWindow } from 'src/animations/scroll';

import * as style from './Questions.module.scss';
import Accordion from 'src/components/Accordion';

const Questions: FC = () => {
  // hooks
  const data = useQuestionsData();

  useEffect(() => {
    const animation = appearanceOfText(`.${style.animatedText}`).pause();

    onHTMLElementEnterOnWindow(`.${style.animatedText}`, () => {
      animation.play();
    });
  }, []);

  return (
    <Section className={style.root}>
      <Accordion
        className={style.row}
        Title={(props) => (
          <div className={style.title}>
            <Typography
              className={classNames(style.animatedText, style.text)}
              variant='h4'
            >
              {props.text}
            </Typography>
            <div
              className={classNames(style.iconWrapper, {
                [style.opened]: props.activeRow,
              })}
            />
          </div>
        )}
        Body={(props) => (
          <Typography className={style.description} variant='body'>
            {props.text}
          </Typography>
        )}
        list={data.questionList.map((item) => ({
          title: item.question,
          body: item.answer,
        }))}
      />
    </Section>
  );
};

export default Questions;
