import { FC, memo, useCallback, useContext, useMemo, useRef } from 'react';
import classNames from 'classnames';
// context
import CursorContext from 'src/contexts/CursorContext';
// hooks
import useForceUpdate from 'src/hooks/useForceUpdate';

import type { Props, ItemProps } from './Accordion.interface';
import * as style from './Accordion.module.scss';

const Item: FC<ItemProps> = memo(
  ({ index, className, Title, Body, onToggle, title, body, isActive }) => {
    // context
    const { cursor } = useContext(CursorContext);
    // refs
    const contentRef = useRef<HTMLDivElement>(null);
    // memo
    const contentHeight = useMemo<number>(
      () => contentRef.current?.scrollHeight ?? 0,
      [contentRef.current]
    );

    const handleMouseEnter = () => {
      cursor?.enter();
    };

    const handleMouseLeave = () => {
      cursor?.leave();
    };

    const handleTitleClick = useCallback(() => {
      onToggle(index);
    }, [index, onToggle]);

    return (
      <div className={className}>
        <div
          onClick={handleTitleClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Title text={title} activeRow={isActive} />
        </div>
        <div
          ref={contentRef}
          className={classNames(style.body)}
          style={{ height: isActive ? contentHeight : 0 }}
        >
          <Body text={body} />
        </div>
      </div>
    );
  }
);

// eslint-disable-next-line react/no-multi-comp
const Accordion: FC<Props> = ({
  className,
  list,
  Title,
  Body,
  variant,
  onRowClick,
}) => {
  // hooks
  const { forceUpdate } = useForceUpdate();
  // state
  const activeRowRef = useRef<number | null>(
    variant === 'alwaysOpen' ? 0 : null
  );

  const handleRowClick = useCallback(
    (index: number) => {
      if (index === activeRowRef.current && variant === 'alwaysOpen') {
        return;
      }

      if (index === activeRowRef.current) {
        activeRowRef.current = null;
      } else {
        activeRowRef.current = index;
      }

      onRowClick && onRowClick(index);

      forceUpdate();
    },
    [onRowClick]
  );

  return (
    <>
      {list.map((item, index) => (
        <Item
          key={index}
          index={index}
          className={className}
          onToggle={handleRowClick}
          Title={Title}
          Body={Body}
          isActive={index === activeRowRef.current}
          {...item}
        />
      ))}
    </>
  );
};

export default Accordion;
